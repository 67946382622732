import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Button, Grid } from "@mui/material";

import ButtonBlock from "../../components/ButtonBlock";
import LinkButton from "../../components/LinkButton";
import LoadingSpinner from "../../components/LoadingSpinner";
import { isDeveloper, isStandardUser, isAdminOrClientManager } from "../../helpers/AuthUserHelper";
import CurrentUser from "../../models/CurrentUser";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import ConfigurationConsentSettings from "../components/ConfigurationConsentSettings";
import ConfigurationGeneralSettings from "../components/ConfigurationGeneralSettings";
import ConfigurationInsightSettings from "../components/ConfigurationInsightSettings";
import ConfigurationPreConsentSettings from "../components/ConfigurationPreConsentSettings";
import ViewEditConfigurationTabs from "../components/ViewEditConfigurationTabs";
import Configuration from "../models/Configuration";
import TabItemDescription from "../models/TabItemDescription";
import UploadFile from "../models/UploadFile";
import NotFound from "./NotFound";
import ConfigurationDeveloperToolsSettings from "../components/ConfigurationDeveloperToolsSettings";
import ConfigurationDashboardSettings from "../components/ConfigurationDashboardSettings";
import ImagePreviews from "../models/ImagePreviews";

interface ViewEditConfigurationProps
{
    adminObApi: OpenBankingPlatformAPIInterface,
    basePath: string,
    onUpdateConfiguration?: (configurationId: number, newConfiguration: Configuration, fileUploads?: Array<UploadFile>, imagePreviews?: ImagePreviews) => void,
    refreshConfigurationList?: () => void,
    configurations: Array<Configuration>
    currentUser?: CurrentUser,
    accountId: number,
    isLoading: boolean,
    mtlsCertOptions?: Array<any>,
    imagePreviews?: ImagePreviews
}

interface ViewEditConfigurationState
{
    mode: "view" | "edit",
    activeTabIndex: number,
    configurations: Array<Configuration>
    configNotFound: boolean
}

const ViewEditConfiguration = (props: ViewEditConfigurationProps) => {
    const {configId} = useParams<{ configId: string, tabId: string }>();
    const configurationId = Number(configId);

    const [state, setState] = useState<ViewEditConfigurationState>(
        {
            mode: "view",
            activeTabIndex: 0,
            configurations: [],
            configNotFound: false
        }
    );

    let configuration = null;
    if (isNaN(configurationId)) {
        let userPrincipalId = props.currentUser?.principal_id;
        for (let i = 0; i < props.configurations.length; i++) {
            let configPrincipalID = parseInt(props.configurations[i].principal_id as unknown as string);
            if (userPrincipalId === configPrincipalID) {
                configuration = props.configurations[i];
                break;
            }
        }
    } else {
        configuration = props.configurations.find(configuration => configuration.id === configurationId);
    }
/*
    if (!configuration) {
        //throw Error("Invalid parameter");
        setState({
            ...state,
            configNotFound: true
        })
    }*/

    const tabs: Array<TabItemDescription> = [
        {
            id: `general-settings`,
            text: `General Settings`,
        },
        {
            id: `pre-consent`,
            text: `Pre-Consent`,
        },
        (configuration && configuration.generalSettings.incInsightStage
            ? {id: `insight`, text: `Insight`} 
            : {id: `consent`, text: `Consent`}),
        {
            id: `dashboard`,
            text: 'Dashboard'
        },
        {
            id: `dev-tools`,
            text: `Developer Tools`,
        }
    ];

    const nextTab = () => {
        const newTabIndex = (state.activeTabIndex + 1 >= tabs.length) ? 0 : state.activeTabIndex + 1;
        setState({
            ...state,
            activeTabIndex: newTabIndex,
        })
        window.scrollTo(0, 0);
    }

    const prevTab = () => {
        const newTabIndex = (state.activeTabIndex + 1 > tabs.length) ? 0 : state.activeTabIndex - 1;
        setState({
            ...state,
            activeTabIndex: newTabIndex,
        })
        window.scrollTo(0, 0);
    }

    const switchToTab = (newTabIndex: number) => {
        setState({
            ...state,
            activeTabIndex: newTabIndex,
        })
    }

    const switchMode = (newMode: "view" | "edit", viewScrollToTop = true) => {
        setState({
            ...state,
            mode: newMode,
        })
        if (viewScrollToTop) {
            window.scrollTo(0, 0);
        }
    }

    const handleConfigurationUpdate = (newConfiguration: Configuration, mode: 'view' | 'edit', viewScrollToTop = true, fileUploads?: Array<UploadFile>, imagePreviews?: ImagePreviews) => {
        props.onUpdateConfiguration && props.onUpdateConfiguration(configurationId, newConfiguration, fileUploads, imagePreviews);
        switchMode(mode, viewScrollToTop);
    }

    const refreshConfigurationList = () => {
        props.refreshConfigurationList && props.refreshConfigurationList();
    }

    const handleCancel = () => {
        switchMode("view");
    }

    const editor = ((tabIndex: number) => {
        if (configuration) {
            switch (tabIndex) {
                case 0:
                    return <ConfigurationGeneralSettings basePath={props.basePath}
                                                         configuration={configuration} mode={state.mode} key={tabIndex}
                                                         onCancel={handleCancel}
                                                         currentUser={props.currentUser}
                                                         onConfigurationUpdate={handleConfigurationUpdate}
                                                         refreshConfigurationList={refreshConfigurationList}
                                                         adminObApi={props.adminObApi}
                                                         mtlsCertOptions={props.mtlsCertOptions}
                                                         imagePreviews={props.imagePreviews}/>
                case 1:
                    return <ConfigurationPreConsentSettings basePath={props.basePath}
                                                            api={props.adminObApi}
                                                            configuration={configuration} mode={state.mode} key={tabIndex}
                                                            onCancel={handleCancel}
                                                            currentUser={props.currentUser}
                                                            onConfigurationUpdate={handleConfigurationUpdate}
                                                            imagePreviews={props.imagePreviews}/>
                case 2:
	               return configuration.generalSettings.incInsightStage
	                    ? <ConfigurationInsightSettings basePath={props.basePath}
	                                                     configuration={configuration} mode={state.mode} key={tabIndex}
	                                                     onCancel={handleCancel}
	                                                     currentUser={props.currentUser}
	                                                     adminObApi={props.adminObApi}
	                                                     onConfigurationUpdate={handleConfigurationUpdate}/>
	                    : <ConfigurationConsentSettings basePath={props.basePath}
	                                                     configuration={configuration} mode={state.mode} key={tabIndex}
	                                                     onCancel={handleCancel}
	                                                     currentUser={props.currentUser}
	                                                     onConfigurationUpdate={handleConfigurationUpdate}/>
                case 3:
                    return <ConfigurationDashboardSettings basePath={props.basePath}
                                                           configuration={configuration} mode={state.mode} key={tabIndex}
                                                           onCancel={handleCancel}
                                                           currentUser={props.currentUser}
                                                           onConfigurationUpdate={handleConfigurationUpdate}/>
                case 4:
                    return <ConfigurationDeveloperToolsSettings
                                                    configuration={configuration} mode={state.mode} 
                                                    onCancel={handleCancel}
                                                    currentUser={props.currentUser}
                                                    onConfigurationUpdate={handleConfigurationUpdate}/>
                default:
                    return <></>
            }
        }
    })(state.activeTabIndex);



    return <>{(props.isLoading) ? <LoadingSpinner position={"fixed"} overlay /> :
        <div>
            {(configuration) ?
                <div className={"view-edit-configuration"} key={4}>
                    <div className={"tabs-container"}>
                        <ViewEditConfigurationTabs
                            tabs={tabs}
                            activeTabIndex={state.activeTabIndex}
                            backPath={props.basePath}
                            basePath={props.basePath + "/view"}
                            configurationId={configurationId}
                            onTabChange={switchToTab}
                            accountId={props.accountId}
                            currentUser={props.currentUser}
                        />
                    </div>

                    {editor}

                    <Grid container className={"mt-20"}>
                        <Grid item xs={12}>
                            <ButtonBlock className={"text-align-center"}>
                                {(state.mode === "view") &&
                                <>
                                    {(state.activeTabIndex === 0 && isDeveloper(props.currentUser?.role_id)) &&
                                    <Button onClick={() => switchMode("edit")} variant={"contained"} color={"secondary"}>Edit Settings</Button>
                                    }

                                    {(state.activeTabIndex > 0) &&
                                    <Button onClick={() => prevTab()} variant={"contained"} color={"secondary"}>Previous</Button>
                                    }

                                    {(props.currentUser?.role_id && !isDeveloper(props.currentUser?.role_id) && !isStandardUser(props.currentUser.role_id) &&
                                        <Button onClick={() => switchMode("edit")} variant={"contained"} color={"secondary"}>Edit
                                            Settings</Button>
                                        )}

                                    {(state.activeTabIndex < tabs.length - 1)
                                        ? <Button onClick={() => nextTab()} variant={"contained"} color={"secondary"}>Next</Button>
                                        : (isAdminOrClientManager(props.currentUser?.role_id)) ?
                                            <LinkButton targetlocation={props.basePath + "/account/" + props.accountId} variant={"contained"} color={"secondary"}>Done</LinkButton>
                                            : ""
                                    }
                                </>
                                }

                            </ButtonBlock>
                        </Grid>
                    </Grid>
                </div>
                : <NotFound /> }
        </div>}</>;
}

export default ViewEditConfiguration;
