import * as EmailValidator from "email-validator";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import {Button, Container, InputBase} from "@mui/material";

import ButtonBlock from "../components/ButtonBlock";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LoadingSpinner from "../components/LoadingSpinner";
import PrimaryBlock from "../components/PrimaryBlock";
import { consentRedirectByErrorResponseStatus } from "../helpers/RedirectHelper";
import CustomerDetailsData from "../models/CustomerDetailsData";
import GeneralSettings from "../models/GeneralSettings";
import PreConsent from "../models/PreConsent";
import PreConsentData from "../models/PreConsentData";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";
import DeveloperTools from "../models/DeveloperTools";
import Broker from "../admin/models/Broker";
import {
    getTrustedAdviserBrokerName,
    getTrustedAdviserBrokerNumber,
    getTrustedAdviserBrokerSubType
} from "../helpers/TrustedAdvisersBrokerHelper";


interface CustomerDetailsInputsProps {
    basePath: string;
    api: OpenBankingPlatformAPI;
    next: string;
    generalSettingsConfig?: GeneralSettings;
    preConsentConfig?: PreConsent;
    loadedPreConsentData?: PreConsentData;
    developerToolsConfig?: DeveloperTools;
    hasApiError?: boolean;
    principalLogoUrl?: string;
    headerBgImageUrl?: string;
    footerText?: string;
    isTrustedAdviser?: boolean;
    broker?: Broker;
}

interface CustomerDetailsInputsState {
    isLoading: boolean;
}

const CustomerDetailsInputs = (props: CustomerDetailsInputsProps) => {

    const [state, setState] = useState<CustomerDetailsInputsState>({
        isLoading: false
    });

    const [customerDetailsInputs, setCustomerDetailsInputs] = useState<CustomerDetailsData>({
        given_name: '',
        surname: '',
        email: '',
    });

    const setGivenName = (newGivenName: string) => {
        setCustomerDetailsInputs({ ...customerDetailsInputs, given_name: newGivenName });
    }

    const setSurname = (newSurname: string) => {
        setCustomerDetailsInputs({ ...customerDetailsInputs, surname: newSurname });
    }

    let emailValidationError: any = null;

    const validateEmail = (newEmail?: string) => {
        if (newEmail) {
            return EmailValidator.validate(newEmail);
        }
        return false;
    }

    const setEmail = (newEmail: string) => {
        setCustomerDetailsInputs({ ...customerDetailsInputs, email: newEmail });
        emailValidationError.innerHTML = "";
        if (newEmail !== "") {
            if (!validateEmail(newEmail)) {
                emailValidationError.innerHTML = "Please enter a valid email address";
            }
        }
    }

    const allMandatoryFieldsArePopulated = customerDetailsInputs.given_name !== '' && customerDetailsInputs.surname !== '' && customerDetailsInputs.email !== '';
    const hasValidEmail = validateEmail(customerDetailsInputs.email);

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    //eslint-disable-next-line
    const saveCustomerDetailsData = useCallback(props.api.saveCustomerDetailsData, []);
    const handleCustomerDetailsSubmit = () => {
        if (!allMandatoryFieldsArePopulated) {
            return;
        }

        if (!validateEmail(customerDetailsInputs.email)) {
            return;
        }

        //Save and send to server
        (async () => {
            try {
                setState({
                    isLoading: true
                });
                const customerDetails = await saveCustomerDetailsData(customerDetailsInputs);
                sessionStorage.setItem('customer', JSON.stringify(customerDetails.customerDetails));
                navigate(props.next);
            } catch (error) {
                console.log(error);
                consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();
    }

    return <div className={"page-wrapper customer-details"}>
        <div className={"page-top"}>
            <main>
                <Header
                    generalSettings={props.generalSettingsConfig}
                    principalLogoUrl={props.principalLogoUrl}
                    headerBgImageUrl={props.headerBgImageUrl}
                    isTrustedAdviser={props.isTrustedAdviser}
                />
                <div className={"page-shadow"}>
                {(state.isLoading) ? <LoadingSpinner position={"fixed"} overlay /> :

                    (props.developerToolsConfig && props.developerToolsConfig?.incUnknownError)
                        ? <div className={"pager"}>
                            <h3 className={"error-title"}>Unknown Error</h3>
                            <p className={"error-text"}>We're sorry but our system has encountered an error. Please try again later or contact support.</p>
                        </div> :
                        (props.generalSettingsConfig)
                            ?
                            <>
                                {props.isTrustedAdviser &&
                                    <Container maxWidth="md" className="pt-4">
                                        <div className="customer-details-input-section">
                                            <h4>You are being asked to share your data with a Trusted Adviser:</h4>
                                            <h5>{ getTrustedAdviserBrokerName(props.broker) }</h5>
                                            <h5>{ getTrustedAdviserBrokerSubType(props.broker) }: { getTrustedAdviserBrokerNumber(props.broker) }</h5>
                                        </div>
                                    </Container>
                                }

                                <form>
                                    <PrimaryBlock>
                                        <Container maxWidth="xs" className="p-0">

                                            <div className="customer-details-input-section">
                                                <h5>Please provide your details below:</h5>
                                                <div className="customer-details-inputs">
                                                    <div>
                                                        <InputBase
                                                            id="given_name"
                                                            placeholder="Given Name"
                                                            inputProps={{ "aria-label": "Given Name" }}
                                                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                                                setGivenName(event.target.value as string)
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <InputBase
                                                            id="surname"
                                                            placeholder="Surname"
                                                            inputProps={{ "aria-label": "Surname" }}
                                                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                                                setSurname(event.target.value as string)
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <InputBase
                                                            id="email"
                                                            placeholder="Email"
                                                            inputProps={{ "aria-label": "Email" }}
                                                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                                                setEmail(event.target.value as string)
                                                            }}
                                                        />
                                                        <p className="mandatory-fields-hint" ref={(message) => { emailValidationError = message; }}></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Container>

                                        <Container maxWidth="sm" className="p-0">
                                            <div className="customer-details-input-section">
                                                <ButtonBlock>
                                                    { !props.isTrustedAdviser ?
                                                        <Button onClick={handleGoBack} variant={"outlined"} color={"secondary"}>Cancel</Button> : ""
                                                    }

                                                    <Button onClick={handleCustomerDetailsSubmit} variant={"contained"} color={"secondary"}
                                                            disabled={!allMandatoryFieldsArePopulated || !hasValidEmail}>
                                                        { props.isTrustedAdviser ? "Proceed" : "Continue" }
                                                    </Button>

                                                </ButtonBlock>
                                            </div>
                                        </Container>
                                    </PrimaryBlock>
                                </form>

                            </>
                            : (props.hasApiError)
                                ? <>
                                    <h3 className={"error-title"}>Error</h3>
                                    <p className={"error-text"}>We're sorry but our system has encountered an error. Please try
                                        again later or contact support.</p>
                                </>
                                : <LoadingSpinner position={"fixed"} overlay />
                }
                                
            </div>    
            </main>
        </div>

        <Footer generalSettingsConfig={props.generalSettingsConfig} isTrustedAdvisor={props.isTrustedAdviser} />

    </div>;
}

export default CustomerDetailsInputs;