import moment from "moment";
import React, { ChangeEvent, MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { faAngleRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoIcon from "@mui/icons-material/Info";
import { Autocomplete, Box, Button, Container, Divider, FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextField, Theme, ThemeProvider, Typography } from "@mui/material";

import AccreditationBadge from "../../components/AccreditationBadge";
import ButtonBlock from "../../components/ButtonBlock";
import SwitchWithLabel from "../../components/SwitchWithLabel";
import { supportedFonts } from "../../helpers/FontHelper";
import {
    checkIfUploadedFileHasImageMimeType, checkIfUploadedFileHasValidPdfSignature, checkIfUploadedFileSizeWithinLimit,
    hasRichTextSpecifiedForField,
    validateIfTargetsAreValidEmails,
    validateIfTargetsAreValidUrls
} from "../../helpers/InputFieldValidationHelper";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import { DarkMode, LightMode } from "../../helpers/ThemeHelper";
import GeneralSettings, { EnhancedRedirectionSettings } from "../../models/GeneralSettings";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import Popup from "../../popups/Popup";
import ThemeBuilder from "../../ThemeBuilder";
import CdrRetrievalOverridedDataHolder from "../models/CdrRetrievalOverridedDataHolder";
import UploadFile from "../models/UploadFile";
import FileItem from "./FileItem";
import LightTooltip from "./LightTooltip";
import {isAdmin, isAdminOrClientManager} from "../../helpers/AuthUserHelper";
import CurrentUser from "../../models/CurrentUser";
import ImagePreviews from "../models/ImagePreviews";
import EditableRichText from "./EditableRichText";
import {convertToRaw, EditorState} from "draft-js";
import ColorPickerComponent from "./ColorPickerComponent";
import FontFamilyPicker from "./FontFamilyPicker";
import { sanitize } from "../../helpers/HtmlSanitizerHelper";
import Header from "../../components/Header";

interface EditGeneralSettingsProps {
    basePath: string,
    generalSettings: GeneralSettings,
    onGeneralSettingsChange?: (newGeneralSettings: GeneralSettings, newFileUpload?: UploadFile, imagePreviews?: ImagePreviews) => void,
    configurationName: string,
    configurationDescription: string,
    onConfigurationNameChange?: (name: string) => void,
    onConfigurationDescriptionChange?: (description: string) => void,
    statementDeliveryEmailRef: React.MutableRefObject<any>,
    statementDeliveryEndpointRef: React.MutableRefObject<any>,
    consentExpiryEndpointRef: React.MutableRefObject<any>,
    adminObApi: OpenBankingPlatformAPIInterface,
    configurationPrincipalId?: number,
    previewSettings?: boolean,
    onClosePreviewSettings?: () => void,
    currentUser?: CurrentUser,
    mtlsCertOptions?: Array<any>,
    imagePreviews?: ImagePreviews
}

const EditGeneralSettings = (props: EditGeneralSettingsProps) => {
    const styles = {
        dataClusterCard: {
            background: props.generalSettings.secondaryColor,
            color: props.generalSettings.secondaryBGTextColor,
            borderRadius: "4px"
        },
        primaryBlock: {
            background: props.generalSettings.backgroundColor,
            color: props.generalSettings.textColor
        },
        secondaryBlock: {
            background: props.generalSettings.secondaryColor,
            color: props.generalSettings.secondaryBGTextColor
        },
        footerBlock: {
            fontFamily: props.generalSettings.footerFontFamily,
            background: props.generalSettings.footerBackgroundColor,
            color: props.generalSettings.footerTextColor,
            padding: '20px 0',
            borderTop: '1px solid #ccc',
          },
          formItem: {
            marginBottom: '16px'
          },
          submitButton: {
            marginTop: '8px'
          },
        separator: {
            borderTopColor: props.generalSettings.textColor
        }
    };

    const navigate = useNavigate();

    const [status, setStatus] = useState('');
    const [status2, setStatus2] = useState('');
    const [status3, setStatus3] = useState('');
    const [dataHolders, setDataHolders] = useState<Array<{ name: string; identifier: string; }>>([]);
    //const [selectedDataHolders, setSelectedDataHolders] = useState<Array<{name: string;identifier: string;}>>([]);

    const getCurrentColours = () => {
        return {
            backgroundColor: props.generalSettings?.backgroundColor ?? LightMode.backgroundColor,
            textColor: props.generalSettings?.textColor ?? LightMode.textColor,

            primaryColor: props.generalSettings?.primaryColor ?? LightMode.primaryColor,
            primaryBGTextColor: props.generalSettings?.primaryBGTextColor ?? LightMode.primaryBGTextColor,

            secondaryColor: props.generalSettings?.secondaryColor ?? LightMode.secondaryColor,
            secondaryBGTextColor: props.generalSettings?.secondaryBGTextColor ?? LightMode.secondaryBGTextColor,

            tertiaryColor: props.generalSettings?.tertiaryColor ?? LightMode.tertiaryColor,
            tertiaryBGTextColor: props.generalSettings?.tertiaryBGTextColor ?? LightMode.tertiaryBGTextColor,

            successColor: props.generalSettings?.successColor ?? LightMode.successColor,
            successBGTextColor: props.generalSettings?.successBGTextColor ?? LightMode.successBGTextColor,

            primaryButtonTextStyle: props.generalSettings?.primaryButtonTextStyle ?? LightMode.primaryButtonTextStyle,
            inverseButtonStyle: props.generalSettings?.inverseButtonStyle ?? LightMode.inverseButtonStyle,
            inverseButtonTextStyle: props.generalSettings?.inverseButtonTextStyle ?? LightMode.inverseButtonTextStyle,
            buttonHoverEffect: props.generalSettings?.buttonHoverEffect ?? LightMode.buttonHoverEffect,
            linkHoverEffect: props.generalSettings?.linkHoverEffect ?? LightMode.linkHoverEffect
        }
    }

    const [customColours, setCustomColours] = useState(getCurrentColours());

    const getThemePreset = (themeType: string) => {
        if (themeType === "Light mode") return LightMode;
        if (themeType === "Dark mode") return DarkMode;
        return customColours;
    }

    const customTheme = ThemeBuilder(
        {
            theme: 'Custom',
            generalSettings: props.generalSettings
        }
    );

    const [openIVE, setOpenIVE] = React.useState(false);

    const openIVEPopup = () => {
        setOpenIVE(true);
    };
    const closeIVEPopup = () => {
        setOpenIVE(false);
    };

    const [openIVE2, setOpenIVE2] = React.useState(false);

    const openIVEPopup2 = () => {
        setOpenIVE2(true);
    };
    const closeIVEPopup2 = () => {
        setOpenIVE2(false);
    };

    const [openIVE3, setOpenIVE3] = React.useState(false);


    const openIVEPopup3 = () => {
        setOpenIVE3(true);
    };
    const closeIVEPopup3 = () => {
        setOpenIVE3(false);
    };

    const [openIVE4, setOpenIVE4] = React.useState(false);

    const openIVEPopup4 = () => {
        setOpenIVE4(true);
    };
    const closeIVEPopup4 = () => {
        setOpenIVE4(false);
    };

    const [openIVE5, setOpenIVE5] = React.useState(false);

    const openIVEPopup5 = () => {
        setOpenIVE5(true);
    };
    const closeIVEPopup5 = () => {
        setOpenIVE5(false);
    };

    const [openPVE, setOpenPVE] = React.useState(false);

    const openPVEPopup = () => {
        setOpenPVE(true);
    };
    const closePVEPopup = () => {
        setOpenPVE(false);
    };


    //eslint-disable-next-line
    const getDataHolders = useCallback(props.adminObApi.getDataHolders, []);
    const doGetDataHolders = () => {
        (async () => {
            try {
                const holdersData = await getDataHolders();
                setDataHolders(holdersData.filter(el => {
                    return el.is_cdr
                }).map((el) => ({ name: el.name, identifier: el.identifier })));
            } catch (error) {
                //console.log(error);
                adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();
    }

    useEffect(() => {
        doGetDataHolders();
        //setSelectedDataHolders(dataHolders.filter(el => el.identifier === "bankwest"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    const getSelectedCdrRetrievalOverridedDataHoldersOptions = () => {
        var cdrRetrievalOverridedDataHolders = props.generalSettings.cdrRetrievalOverridedDataHolders;
        var filteredDataHolders = dataHolders.filter(
            function (dataHolder) {
                if (cdrRetrievalOverridedDataHolders) {
                    for (var i = 0; i < cdrRetrievalOverridedDataHolders.length; i++) {
                        if (dataHolder.identifier === cdrRetrievalOverridedDataHolders[i].identifier) {
                            return true;
                        }
                    }
                }
                return false;
            }
        );
        return filteredDataHolders;
    }

    const cdrFileUploadLogo = useRef() as MutableRefObject<HTMLInputElement>;
    const cdrFileUploadPolicyPDF = useRef() as MutableRefObject<HTMLInputElement>;
    const fileUploadHeaderBg = useRef() as MutableRefObject<HTMLInputElement>;
    const accreditationUploadIcon = useRef() as MutableRefObject<HTMLInputElement>;

    const handleGeneralSettingsChange = (newGeneralSettings: GeneralSettings, newFileUpload?: UploadFile, imagePreviews?: ImagePreviews) => {
        props.onGeneralSettingsChange && props.onGeneralSettingsChange(newGeneralSettings, newFileUpload, imagePreviews);
    }

    const handleEnhancedRedirectionSettingsChange = (newEnhancedRedirectionSettings: EnhancedRedirectionSettings) => {
        handleGeneralSettingsChange({
            ...props.generalSettings,
            enhancedRedirection: newEnhancedRedirectionSettings
        });
    }

    const handleUploadClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        if (event.currentTarget.id === "linkUploadPolicyPDF") cdrFileUploadPolicyPDF.current.click();
        if (event.currentTarget.id === "linkUploadLogo") cdrFileUploadLogo.current.click();
        if (event.currentTarget.id === "linkUploadHeaderBg") fileUploadHeaderBg.current.click();
        if (event.currentTarget.id === "linkUploadIcon") accreditationUploadIcon.current.click();
    }

    const handleCaptureUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files === null) return
        var file = event.target.files[0]

        var imagePreviews = {};

        if (event.currentTarget.id === "cdrUploadPolicyPDF") {
            checkIfUploadedFileHasValidPdfSignature(file, function(isValidPdf:boolean) {
                if (!checkIfUploadedFileSizeWithinLimit(file)) {
                    openIVEPopup5();
                    return;
                }

                if (!isValidPdf) {
                    openPVEPopup();
                } else {
                    handleGeneralSettingsChange(Object.assign({}, { ...props.generalSettings, cdrPolicyPDF: file.name, cdrPolicyPDFUploadDate: moment().format("DD/MM/YYYY") }),
                        {
                            name: event.target.id,
                            path: `/cdr-policy/${props.configurationPrincipalId}`,
                            file: file
                        });
                }
            });
        }

        if (event.currentTarget.id === "cdrUploadLogo") {
            var img = new Image()
            const objectUrl = URL.createObjectURL(file)

            checkIfUploadedFileHasImageMimeType(file, function(isValidImage:boolean) {
                if (!checkIfUploadedFileSizeWithinLimit(file)) {
                    openIVEPopup5();
                    return;
                }

                if (!isValidImage) {
                    openIVEPopup4();
                } else {
                    img.onload = function () {
                        if (img.width <= 500 && img.height <= 500) {
                            setLogoPreviewSrc(objectUrl);
                            imagePreviews = Object.assign(imagePreviews, { cdrFileUploadLogoPreviewURL: objectUrl});
                            handleGeneralSettingsChange(Object.assign({}, { ...props.generalSettings, cdrUploadLogo: file.name, cdrUploadLogoDate: moment().format("DD/MM/YYYY") }),
                                {
                                    name: event.target.id,
                                    path: `/principal-logos/${props.configurationPrincipalId}`,
                                    file: file
                                }, imagePreviews);
                            setStatus('updated');
                        }
                        else {
                            openIVEPopup()
                        }
                    };
                    img.src = objectUrl
                }
            });
        }

        if (event.currentTarget.id === "uploadHeaderBg") {
            var img2 = new Image()
            const objectUrl2 = URL.createObjectURL(file)

            checkIfUploadedFileHasImageMimeType(file, function(isValidImage:boolean) {
                if (!checkIfUploadedFileSizeWithinLimit(file)) {
                    openIVEPopup5();
                    return;
                }

                if (!isValidImage) {
                    openIVEPopup4();
                } else {
                    img2.onload = function () {
                        if (img2.width <= 1920 && img2.height <= 128) {
                            setHeaderBgPreviewSrc(objectUrl2);
                            imagePreviews = Object.assign(imagePreviews, { uploadHeaderBgPreviewURL: objectUrl2});
                            handleGeneralSettingsChange(Object.assign({}, { ...props.generalSettings, headerBackgroundImage: file.name, headerBackgroundImageUploadDate: moment().format("DD/MM/YYYY") }),
                                {
                                    name: event.target.id,
                                    path: `/header-backgrounds/${props.configurationPrincipalId}`,
                                    file: file
                                }, imagePreviews);
                            setStatus2('updated');
                        }
                        else {
                            openIVEPopup2()
                        }
                    };
                    img2.src = objectUrl2
                }
            });
        }

        if (event.currentTarget.id === "uploadAccreditationIcon") {
            var img3 = new Image()
            const objectUrl3 = URL.createObjectURL(file)

            checkIfUploadedFileHasImageMimeType(file, function(isValidImage:boolean) {
                if (!checkIfUploadedFileSizeWithinLimit(file)) {
                    openIVEPopup5();
                    return;
                }

                if (!isValidImage) {
                    openIVEPopup4();
                } else {
                    img3.onload = function () {
                        if (img3.width <= 500 && img3.height <= 500) {
                            setIconPreviewSrc(objectUrl3);
                            imagePreviews = Object.assign(imagePreviews, { accreditationUploadIconPreviewURL: objectUrl3});
                            handleGeneralSettingsChange(Object.assign({}, { ...props.generalSettings, accreditationUploadIcon: file.name, accreditationUploadIconDate: moment().format("DD/MM/YYYY") }),
                                {
                                    name: event.target.id,
                                    path: `/accreditation-icons/${props.configurationPrincipalId}`,
                                    file: file
                                }, imagePreviews);
                            setStatus3('updated');
                        }
                        else {
                            openIVEPopup3()
                        }
                    };
                    img3.src = objectUrl3
                }
            });
        }
    }

    const logoUrl = `/principal-logos/${props.configurationPrincipalId}/${props.generalSettings.cdrUploadLogo}`;
    const [logoSrc, setLogoSrc] = useState('');
    const [logoPreviewSrc, setLogoPreviewSrc] = useState('');

    const headerBgUrl = `/header-backgrounds/${props.configurationPrincipalId}/${props.generalSettings.headerBackgroundImage}`;
    const [headerBgSrc, setHeaderBgSrc] = useState('');
    const [headerBgPreviewSrc, setHeaderBgPreviewSrc] = useState('');

    const iconUrl = `/accreditation-icons/${props.configurationPrincipalId}/${props.generalSettings.accreditationUploadIcon}`;
    const [iconSrc, setIconSrc] = useState('');
    const [iconPreviewSrc, setIconPreviewSrc] = useState('');

    //eslint-disable-next-line
    const getFile = useCallback(props.adminObApi.getFile, []);
    useEffect(() => {
        (async (logoUrl, headerBgUrl, iconUrl) => {
            if (!props.imagePreviews?.cdrFileUploadLogoPreviewURL && props.generalSettings.cdrUploadLogo !== '' && status !== 'updated') {
                try {
                    const data = await getFile(logoUrl);
                    setLogoSrc(URL.createObjectURL(data));
                    setStatus('loaded');
                } catch (error) {
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            }

            if (!props.imagePreviews?.uploadHeaderBgPreviewURL && props.generalSettings.headerBackgroundImage !== '' && status2 !== 'updated') {
                try {
                    const data = await getFile(headerBgUrl);
                    setHeaderBgSrc(URL.createObjectURL(data));
                    setStatus2('loaded');
                } catch (error) {
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            }

            if (!props.imagePreviews?.accreditationUploadIconPreviewURL && props.generalSettings.accreditationUploadIcon !== '' && status3 !== 'updated') {
                try {
                    const data = await getFile(iconUrl);
                    setIconSrc(URL.createObjectURL(data));
                    setStatus3('loaded');
                } catch (error) {
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            }

        })(logoUrl, headerBgUrl, iconUrl);
        //eslint-disable-next-line
    }, []);

    /*
    useEffect(() => {
        (async (headerBgUrl) => {
            if (!props.imagePreviews?.uploadHeaderBgPreviewURL && props.generalSettings.headerBackgroundImage !== '' && status2 !== 'updated') {
                try {
                    console.log(props.imagePreviews?.uploadHeaderBgPreviewURL);
                    console.log(props.generalSettings.headerBackgroundImage);
                    console.log(status);
                    const data = await getFile(headerBgUrl);
                    setHeaderBgSrc(URL.createObjectURL(data));
                    setStatus2('loaded');
                } catch (error) {
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            }
        })(headerBgUrl);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        (async (iconUrl) => {
            if (!props.imagePreviews?.accreditationUploadIconPreviewURL && props.generalSettings.accreditationUploadIcon !== '' && status3 !== 'updated') {
                try {
                    const data = await getFile(iconUrl);
                    setIconSrc(URL.createObjectURL(data));
                    setStatus3('loaded');
                } catch (error) {
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            }
        })(iconUrl);
        //eslint-disable-next-line
    }, []);
    */


    //eslint-disable-next-line
    const generateApiKey = useCallback(props.adminObApi.generatePrincipalApiKey, []);
    const handleGenerateApiKey = () => {
        (async () => {
            try {
                const newApiKey = await generateApiKey(props.configurationPrincipalId);
                handleGeneralSettingsChange({
                    ...props.generalSettings,
                    principalUuid: newApiKey.new_principal_api_key,
                })
            } catch (error) {
                adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();
    }

    const onEditorStateChange = (field: string, editorState: EditorState) => {
        handleGeneralSettingsChange({
            ...props.generalSettings,
            [field]: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
        });
    };

    let statementDeliveryEmailValidationError: any = null;
    let statementDeliveryEndpointValidationError: any = null;
    let consentExpiryNotificationEndpointValidationError: any = null;
    let reportingDeliveryEmailValidationError: any = null;

    return <>
        <form className={"admin-form general-settings-form"} autoComplete={"off"}>
            <div className={"mt-50"}>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <h4>Principal’s Details</h4>
                        </Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Name<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Principal_name.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required variant="outlined"
                                    name="name"
                                    className="w-100"
                                    value={props.generalSettings.principalName}
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            principalName: event.target.value,
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>ADR Name<LightTooltip
                                    title={"ADR Name"}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="adr-name"
                                    className="w-100"
                                    value={props.generalSettings.adrName}
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            adrName: event.target.value,
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Reference Code<LightTooltip
                                    title={"Principal’s Reference Code"}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="accr-number"
                                    className="w-100"
                                    value={props.generalSettings.referenceCode}
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            referenceCode: event.target.value,
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Is Active?<LightTooltip
                                    title={"Is Configuration Active?"}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Select
                                    id="is-config-active"
                                    name="is-config-active"
                                    value={props.generalSettings.isActive ? props.generalSettings.isActive : 0}
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            isActive: event.target.value as boolean,
                                        })
                                    }}
                                >
                                    <MenuItem value={0}>No</MenuItem>
                                    <MenuItem value={1}>Yes</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <h4>Legal Details</h4>
                        </Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>CDR Policy URL<LightTooltip
                                    title="Consumer data right Policy URL"
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="cdr-policy-url"
                                    className="w-100"
                                    value={props.generalSettings.cdrPolicyURL}
                                    placeholder="CDR policy url"
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            cdrPolicyURL: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>CDR Policy PDF<LightTooltip title="Consumer data right Policy PDF"
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={8} md={5} className={"grid-file-input"}>
                                <div className={"file-item-wrap"}>
                                    <Box className={"details-box"}>
                                        <FileItem type={"generic"} name={props.generalSettings.cdrPolicyPDF} />
                                    </Box>
                                    {props.generalSettings.cdrPolicyPDFUploadDate && <div className={"date"}>Uploaded {props.generalSettings.cdrPolicyPDFUploadDate}</div>}
                                </div>
                                <Box mt={2} className={"input-file-box"}>
                                    <input
                                        color="primary"
                                        accept="application/pdf"
                                        type="file"
                                        onChange={e => {
                                            handleCaptureUploadFile(e);
                                            e.target.value = '';
                                        }}
                                        id="cdrUploadPolicyPDF"
                                        style={{ display: 'none', }}
                                        ref={cdrFileUploadPolicyPDF}
                                    />
                                    <a href={"/#"} id="linkUploadPolicyPDF"
                                        onClick={handleUploadClick}
                                    >
                                        Upload new
                                    </a>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <hr className={"mt-30"} />

            <div className={"mt-50"}>
                <Box mb={5}><h4>General Styling</h4></Box>

                <Box mb={2}><h6>Branding</h6></Box>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Brandmark / brand icon<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Brandmark_brand_icon.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={8} md={5} className={"grid-file-input"}>
                                <div className={"file-item-wrap"}>
                                    <Box className={"details-box"}>
                                        <FileItem type={"image"} name={props.generalSettings.cdrUploadLogo} url={props.imagePreviews?.cdrFileUploadLogoPreviewURL || logoPreviewSrc || logoSrc} />
                                    </Box>
                                    {props.generalSettings.cdrUploadLogoDate && <div className={"date"}>Uploaded {props.generalSettings.cdrUploadLogoDate}</div>}
                                </div>
                                <Box mt={2} className={"input-file-box"}>
                                    <input
                                        color="primary"
                                        accept="image/x-png"
                                        type="file"
                                        onChange={e => {
                                            handleCaptureUploadFile(e);
                                            e.target.value = '';
                                        }}
                                        id="cdrUploadLogo"
                                        style={{ display: 'none', }}
                                        ref={cdrFileUploadLogo}
                                    />
                                    <a href={"/#"} id="linkUploadLogo"
                                        onClick={handleUploadClick}
                                    >
                                        Upload new
                                    </a>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className={"background-grey default-round-corner"} p={3}>
                            Your brandmark will be used for the Data Request part of the process to visually communicate the exchange of data between your business and another. For best results, please use a <strong>PNG file</strong> with a <strong>transparent background</strong>. Your brandmark should be no larger than 500px by 500px and ideally with an aspect ratio of 1:1.
                        </Box>
                    </Grid>
                </Grid>

                <Box mt={5}><h6>Accreditation Badge</h6></Box>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Accreditation number<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Accredation_number.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="accr-number"
                                    className="w-100"
                                    value={props.generalSettings.accreditationNumber}
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            accreditationNumber: event.target.value,
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Show icon in accreditation badge<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Icon_for_accreditation_badge.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} />
                                </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SwitchWithLabel
                                    checked={props.generalSettings.showIconInAccreditationBadge ?? false}
                                    onChange={value => handleGeneralSettingsChange({ ...props.generalSettings, showIconInAccreditationBadge: value })}
                                >
                                    {props.generalSettings.showIconInAccreditationBadge ? "Yes" : "No"}
                                </SwitchWithLabel>
                            </Grid>
                        </Grid>
                        {props.generalSettings.showIconInAccreditationBadge &&
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Icon for accreditation badge<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Icon_for_accreditation_badge.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={8} md={5} className={"grid-file-input"}>
                                    {props.generalSettings.accreditationUploadIcon &&
                                        <div className={"file-item-wrap"}>
                                            <Box className={"details-box"}>
                                                <FileItem type={"image"} name={props.generalSettings.accreditationUploadIcon} url={props.imagePreviews?.accreditationUploadIconPreviewURL || iconPreviewSrc || iconSrc} />
                                            </Box>
                                            {props.generalSettings.accreditationUploadIconDate && <div className={"date"}>Uploaded {props.generalSettings.accreditationUploadIconDate}</div>}
                                        </div>
                                    }
                                    <Box mt={2} className={"input-file-box"}>
                                        <input
                                            color="primary"
                                            accept="image/x-png"
                                            type="file"
                                            onChange={handleCaptureUploadFile}
                                            id="uploadAccreditationIcon"
                                            style={{ display: 'none', }}
                                            ref={accreditationUploadIcon}
                                        />
                                        <a href={"/#"} id="linkUploadIcon"
                                            onClick={handleUploadClick}
                                        >
                                            Upload new
                                        </a>
                                    </Box>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>

                <Box mt={5} mb={2}><h6>Header</h6></Box>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Header background type<LightTooltip
                                    title={"Header background type"}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        name="header-background-type"
                                        value={props.generalSettings.headerBackgroundType}
                                        onChange={(event: SelectChangeEvent) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                headerBackgroundType: event.target.value as string
                                            })
                                        }}
                                    >
                                        <MenuItem value={"none"}>- No background -</MenuItem>
                                        <MenuItem value={"Solid colour"}>Solid colour</MenuItem>
                                        <MenuItem value={"Image background"}>Image background</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {props.generalSettings.headerBackgroundType === "Solid colour" &&
                            <ColorPickerComponent 
                                title={"Header background colour"}
                                tooltipImageSrc={"/images/admin/general-settings/Header_background_colour.png"}
                                color={props.generalSettings.headerBackgroundColor}
                                isCustomColor={true}
                                onColorChange={(color: any) => {
                                    handleGeneralSettingsChange({
                                        ...props.generalSettings,
                                        headerBackgroundColor: color.hex
                                    })
                                }}
                            />
                        }
                        {props.generalSettings.headerBackgroundType === "Image background" ?
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Header background image<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Header_background_image.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6} className={"grid-file-input"}>
                                    <div className={"file-item-wrap"}>
                                        <Box className={"details-box"}>
                                            <FileItem type={"image"} name={props.generalSettings.headerBackgroundImage} url={props.imagePreviews?.uploadHeaderBgPreviewURL || headerBgPreviewSrc || headerBgSrc} />
                                        </Box>
                                        {props.generalSettings.headerBackgroundImageUploadDate && <div className={"date"}>Uploaded {props.generalSettings.headerBackgroundImageUploadDate}</div>}
                                    </div>
                                    <Box mt={2} className={"input-file-box"}>
                                        <input
                                            color="primary"
                                            accept="image/x-png,image/gif,image/jpeg"
                                            type="file"
                                            onChange={handleCaptureUploadFile}
                                            id="uploadHeaderBg"
                                            style={{ display: 'none', }}
                                            ref={fileUploadHeaderBg}
                                        />
                                        <a href={"/#"} id="linkUploadHeaderBg"
                                            onClick={handleUploadClick}
                                        >
                                            Upload new
                                        </a>
                                    </Box>
                                </Grid>
                            </Grid>
                            : ""
                        }
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>
                </Grid>
                <Box mt={5} mb={2}><h6>Footer</h6></Box>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Footer background type<LightTooltip
                                    title={"Footer background type"}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        name="footer-background-type"
                                        value={props.generalSettings.footerBackgroundType}
                                        onChange={(event: SelectChangeEvent) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                footerBackgroundType: event.target.value as string
                                            })
                                        }}
                                    >
                                        <MenuItem value={"none"}>- No background -</MenuItem>
                                        <MenuItem value={"Solid colour"}>Solid colour</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {props.generalSettings.footerBackgroundType === "Solid colour" &&
                            <>
                                <ColorPickerComponent 
                                    title={"Footer background colour"}
                                    tooltipImageSrc={"/images/admin/general-settings/Footer_background_colour.png"}
                                    color={props.generalSettings.footerBackgroundColor}
                                    isCustomColor={true}
                                    onColorChange={(color: any) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            footerBackgroundColor: color.hex
                                        })
                                }}/>
                                <ColorPickerComponent 
                                    title={"Footer text colour"}
                                    tooltipImageSrc={"/images/admin/general-settings/Footer_text_colour.png"}
                                    color={props.generalSettings.footerTextColor}
                                    isCustomColor={true}
                                    onColorChange={(color: any) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            footerTextColor: color.hex
                                        })
                                }}/>
                            </>
                        }
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Footer Text</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <EditableRichText
                                    editMode={true}
                                    value={props.generalSettings.footerText}
                                    onChange={editorState => {
                                        onEditorStateChange('footerText', editorState)
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Footer Text Alignment</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        name="footer-text-alignment"
                                        value={props.generalSettings.footerTextAlignment || 'center'}
                                        onChange={(event: SelectChangeEvent) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                footerTextAlignment: event.target.value as string
                                            })
                                        }}
                                    >
                                        <MenuItem value={"left"}>Left</MenuItem>
                                        <MenuItem value={"center"}>Center</MenuItem>
                                        <MenuItem value={"right"}>Right</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Box mt={3} mb={3}><h6>Typefaces</h6></Box>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <FontFamilyPicker
                            title="Font family for headings"
                            tooltipImageSrc="/images/admin/general-settings/Font_family_for_headings.png"
                            fontFamilyValue={props.generalSettings.headingFontFamily}
                            onFontFamilyChange={(newFontFamily) => handleGeneralSettingsChange({
                                ...props.generalSettings,
                                headingFontFamily: newFontFamily
                            })}
                            supportedFonts={supportedFonts}
                        />
                        <FontFamilyPicker
                            title="Font family for body text"
                            tooltipImageSrc="/images/admin/general-settings/Font_family_for_body_text.png"
                            fontFamilyValue={props.generalSettings.bodyTextFontFamily}
                            onFontFamilyChange={(newFontFamily) => handleGeneralSettingsChange({
                                ...props.generalSettings,
                                bodyTextFontFamily: newFontFamily
                            })}
                            supportedFonts={supportedFonts}
                        />
                        <FontFamilyPicker
                            title="Font family for footers"
                            tooltipImageSrc="/images/admin/general-settings/Font_family_for_footers.png"
                            fontFamilyValue={props.generalSettings.footerFontFamily}
                            onFontFamilyChange={(newFontFamily) => handleGeneralSettingsChange({
                                ...props.generalSettings,
                                footerFontFamily: newFontFamily
                            })}
                            supportedFonts={supportedFonts}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className={"background-grey default-round-corner"} p={3}>
                            Headings are the text or “labels” that appear at the head of a section or page. 
                            The text wrapped in &lt;h1&gt;, &lt;h2&gt; and &lt;h3&gt; tags will be changed affected based on 
                            your “Font family for headings” choice.
                            <br/>
                            “Font family for footers” applies to text in footer of a page.
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                    </Grid>
                </Grid>


                <Box mt={3} mb={3}><h6>Colour system</h6></Box>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Theme type<LightTooltip
                                    title={"Theme type"}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        name="theme-type"
                                        value={props.generalSettings.themeType}
                                        onChange={(event: SelectChangeEvent) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                themeType: event.target.value as string,
                                                ...(getThemePreset(event.target.value as string))
                                            })
                                        }}
                                    >
                                        <MenuItem value={"none"}>- Please choose -</MenuItem>
                                        <MenuItem value={"Light mode"}>Light mode (recommended)</MenuItem>
                                        <MenuItem value={"Dark mode"}>Dark mode</MenuItem>
                                        <MenuItem value={"Custom colours"}>Custom colours</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <ColorPickerComponent 
                            title={"Background colour"}
                            tooltipImageSrc={"/images/admin/general-settings/Background_colour.png"}
                            color={props.generalSettings.backgroundColor}
                            isCustomColor={props.generalSettings.themeType === "Custom colours"}
                            onColorChange={(color: any) => {
                                handleGeneralSettingsChange({
                                    ...props.generalSettings,
                                    themeType: "Custom colours",
                                    backgroundColor: color.hex,
                                })
                                setCustomColours(getCurrentColours())
                            }}
                        />
                        <ColorPickerComponent 
                            title={"Body text colour"}
                            tooltipImageSrc={"/images/admin/general-settings/Body_text_colour.png"}
                            color={props.generalSettings.textColor}
                            isCustomColor={props.generalSettings.themeType === "Custom colours"}
                            onColorChange={(color: any) => {
                                handleGeneralSettingsChange({
                                    ...props.generalSettings,
                                    themeType: "Custom colours",
                                    textColor: color.hex,
                                })
                                setCustomColours(getCurrentColours())
                            }}
                        />
                        <ColorPickerComponent 
                            title={"Primary colour"}
                            tooltipImageSrc={"/images/admin/general-settings/Primary_colour.png"}
                            color={props.generalSettings.primaryColor}
                            isCustomColor={true}
                            onColorChange={(color: any) => {
                                handleGeneralSettingsChange({
                                    ...props.generalSettings,
                                    themeType: "Custom colours",
                                    primaryColor: color.hex
                                })
                                setCustomColours(getCurrentColours())
                            }}
                        />
                        <ColorPickerComponent 
                            title={"Text colour for Primary Background"}
                            tooltipImageSrc={"/images/admin/general-settings/Text_colour_for_primary_background.png"}
                            color={props.generalSettings.primaryBGTextColor}
                            isCustomColor={true}
                            onColorChange={(color: any) => {
                                handleGeneralSettingsChange({
                                    ...props.generalSettings,
                                    themeType: "Custom colours",
                                    primaryBGTextColor: color.hex
                                })
                                setCustomColours(getCurrentColours())
                            }}
                        />
                       <ColorPickerComponent 
                            title={"Secondary colour"}
                            tooltipImageSrc={"/images/admin/general-settings/Secondary_colour.png"}
                            color={props.generalSettings.secondaryColor}
                            isCustomColor={true}
                            onColorChange={(color: any) => {
                                handleGeneralSettingsChange({
                                    ...props.generalSettings,
                                    themeType: "Custom colours",
                                    secondaryColor: color.hex
                                })
                                setCustomColours(getCurrentColours())
                            }}
                        />
                        <ColorPickerComponent 
                             title={"Text colour for \nSecondary Background"}
                             tooltipImageSrc={"/images/admin/general-settings/Text_colour_for_secondary_background.png"}
                             color={props.generalSettings.secondaryBGTextColor}
                             isCustomColor={true}
                             onColorChange={(color: any) => {
                                handleGeneralSettingsChange({
                                    ...props.generalSettings,
                                    themeType: "Custom colours",
                                    secondaryBGTextColor: color.hex
                                })
                                setCustomColours(getCurrentColours())
                            }}
                         />
                         <ColorPickerComponent 
                              title={"Tertiary colour"}
                              tooltipImageSrc={"/images/admin/general-settings/Tertiary_colour.png"}
                              color={props.generalSettings.tertiaryColor}
                              isCustomColor={true}
                              onColorChange={(color: any) => {
                                handleGeneralSettingsChange({
                                    ...props.generalSettings,
                                    themeType: "Custom colours",
                                    tertiaryColor: color.hex
                                })
                                setCustomColours(getCurrentColours())
                            }}
                          />
                          <ColorPickerComponent 
                               title={"Text colour for Tertiary Background"}
                               color={props.generalSettings.tertiaryBGTextColor}
                               isCustomColor={true}
                               onColorChange={(color: any) => {
                                 handleGeneralSettingsChange({
                                     ...props.generalSettings,
                                     themeType: "Custom colours",
                                     tertiaryBGTextColor: color.hex
                                 })
                                 setCustomColours(getCurrentColours())
                             }}
                           />
                        <ColorPickerComponent 
                               title={"Success colour"}
                               tooltipImageSrc="/images/admin/general-settings/Success_colour.png" 
                               color={props.generalSettings.successColor}
                               isCustomColor={true}
                               onColorChange={(color: any) => {
                                 handleGeneralSettingsChange({
                                     ...props.generalSettings,
                                     themeType: "Custom colours",
                                     successColor: color.hex
                                 })
                                 setCustomColours(getCurrentColours())
                             }}
                           />
                           <ColorPickerComponent 
                                  title={"Text colour for Success Background"}
                                  tooltipImageSrc="/images/admin/general-settings/Text_colour_for_success_background.png" 
                                  color={props.generalSettings.successBGTextColor}
                                  isCustomColor={true}
                                  onColorChange={(color: any) => {
                                    handleGeneralSettingsChange({
                                        ...props.generalSettings,
                                        themeType: "Custom colours",
                                        successBGTextColor: color.hex
                                    })
                                    setCustomColours(getCurrentColours())
                                }}
                              />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className={"background-grey default-round-corner"} mt={7} p={3}>
                            <p>The Primary Colour will be displayed for certain Headings, Primary Actionable Components like Buttons and Links, Table Headings and as the background colour for the feature icons. The Primary Colour will also be applied to the Inverse Button through its border colour and text.</p>

                            <h6 className={"mt-30"}>Example Usage</h6>

                            <Grid className={"pt-20"} container spacing={3}>
                                <Grid item xs={4}>Headings</Grid>
                                <Grid item xs={8}>
                                    <Typography color={"secondary"} className={"heading-example mb-0"}>
                                        Lorem Ipsum Heading Here
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>Buttons</Grid>
                                <Grid item xs={8}>
                                    <img style={{ width: 352 }} src="/images/general-settings-button-examples.png" alt="" />
                                </Grid>
                                <Grid item xs={4}>Links</Grid>
                                <Grid item xs={8} className={"buttons"}>
                                    This is body copy, followed by a <strong className="text-link-example">text link</strong>.
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>


                <Box mt={5} mb={3}><h6>Elements / Components</h6></Box>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Primary button text style<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Primary_button_text_style.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        name="primary-button-text-style"
                                        value={props.generalSettings.primaryButtonTextStyle}
                                        onChange={(event: SelectChangeEvent) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                primaryButtonTextStyle: event.target.value as string
                                            })
                                        }}
                                    >
                                        <MenuItem value={"#FFFFFF"}>White</MenuItem>
                                        <MenuItem value={"#000000"}>Black</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Inverse button style<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Inverse_button_style.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        name="inverse-button-style"
                                        value={props.generalSettings.inverseButtonStyle}
                                        onChange={(event: SelectChangeEvent) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                inverseButtonStyle: event.target.value as string
                                            })
                                        }}
                                    >
                                        <MenuItem value={"#FFFFFF"}>White background</MenuItem>
                                        <MenuItem value={"transparent"}>Transparent</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Inverse button text style<LightTooltip
                                    title={<img className="tooltip-image" src="/images/admin/general-settings/Inverse_button_text_style.png" alt="" />}
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        name="secondary-button-text-style"
                                        value={props.generalSettings.inverseButtonTextStyle}
                                        onChange={(event: SelectChangeEvent) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                inverseButtonTextStyle: event.target.value as string
                                            })
                                        }}
                                    >
                                        <MenuItem value={"#FFFFFF"}>White</MenuItem>
                                        <MenuItem value={"#000000"}>Black</MenuItem>
                                        <MenuItem value={props.generalSettings.primaryColor}>Primary colour</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Button hover effect<LightTooltip
                                    title="Button hover effect"
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        name="button-hover-effect"
                                        value={props.generalSettings.buttonHoverEffect}
                                        onChange={(event: SelectChangeEvent) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                buttonHoverEffect: event.target.value as string
                                            })
                                        }}
                                    >
                                        <MenuItem value={"Darken (10%)"}>Darken (10%)</MenuItem>
                                        <MenuItem value={"Darken (30%)"}>Darken (30%)</MenuItem>
                                        <MenuItem value={"Darken (50%)"}>Darken (50%)</MenuItem>
                                        <MenuItem value={"Lighten (10%)"}>Lighten (10%)</MenuItem>
                                        <MenuItem value={"Lighten (30%)"}>Lighten (30%)</MenuItem>
                                        <MenuItem value={"Lighten (50%)"}>Lighten (50%)</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Link hover effect<LightTooltip
                                    title="Button hover effect"
                                    placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        name="link-hover-effect"
                                        value={props.generalSettings.linkHoverEffect}
                                        onChange={(event: SelectChangeEvent) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                linkHoverEffect: event.target.value as string
                                            })
                                        }}
                                    >
                                        <MenuItem value={"Darken (10%)"}>Darken (10%)</MenuItem>
                                        <MenuItem value={"Darken (30%)"}>Darken (30%)</MenuItem>
                                        <MenuItem value={"Darken (50%)"}>Darken (50%)</MenuItem>
                                        <MenuItem value={"Lighten (10%)"}>Lighten (10%)</MenuItem>
                                        <MenuItem value={"Lighten (30%)"}>Lighten (30%)</MenuItem>
                                        <MenuItem value={"Lighten (50%)"}>Lighten (50%)</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className={"background-grey default-round-corner"} p={3}>
                            <p>The Primary button text style refers to the text colour of the Primary Button. The background colour of the Primary Button is inherited from the chosen Primary Colour - see above.</p>

                            <h6 className={"mt-30"} >Example Usage</h6>

                            <Grid className={"pt-20"} container spacing={3}>
                                <Grid item xs={4}>Primary Button</Grid>
                                <Grid item xs={8}>
                                    <img style={{ width: 167 }} src="/images/general-settings-primary-button.png" alt="" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </div>


            <hr className={"mt-30"} />

            <div className={"mt-50"}>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <h4>Insight Settings</h4>
                        </Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Insight Stage</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SwitchWithLabel
                                    checked={props.generalSettings.incInsightStage || false}
                                    onChange={value => handleGeneralSettingsChange({ ...props.generalSettings, incInsightStage: value })}>
                                    {props.generalSettings.incInsightStage ? "Include" : "Exclude"}
                                </SwitchWithLabel>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <hr className={"mt-30"} />

            <div className={"mt-50"}>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <h4>API Settings</h4>
                        </Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Provide Consent Via API<LightTooltip title="Provide Consent Via API" placement="right-start"
                                    arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="provide-consent-via-api"
                                        name="provide-consent-via-api"
                                        value={props.generalSettings.provideConsentViaApi ? props.generalSettings.provideConsentViaApi : 0}
                                        onChange={(event) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                provideConsentViaApi: event.target.value as boolean,
                                            })
                                        }}
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Enable Multibank Sessions<LightTooltip title="Enable Multibank Sessions" placement="right-start"
                                    arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="enable-multibank-sessions"
                                        name="enable-multibank-sessions"
                                        value={props.generalSettings.multibankEnabled ? props.generalSettings.multibankEnabled : 0}
                                        onChange={(event) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                multibankEnabled: event.target.value as boolean,
                                            })
                                        }}
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {isAdminOrClientManager(props.currentUser?.role_id) ?
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>API Key<LightTooltip title="Principal’s API Key" placement="right-start"
                                        arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        disabled
                                        required
                                        variant="outlined"
                                        name="api"
                                        className="w-100"
                                        value={props.generalSettings.principalUuid}
                                    />
                                    <div><a href={"/#"} onClick={(e) => { e.preventDefault(); handleGenerateApiKey(); }}>Generate New API Key</a>&nbsp;</div>
                                </Grid>
                            </Grid> : "" }

                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Statement Delivery Email
                                    <LightTooltip title="Please specify one or more email addresses each separated by semi-colon as a delimiter, e.g. test1@test.com.au; test2@test.com.au; ...." placement="right-start" arrow>
                                        <InfoIcon color={"secondary"} />
                                    </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="statement-delivery-email"
                                    className="w-100"
                                    value={props.generalSettings.statementDeliveryEmail}
                                    onChange={(event) => {
                                        statementDeliveryEmailValidationError.innerHTML = "";
                                        let emails = event.target.value;
                                        statementDeliveryEmailValidationError.innerHTML = "";
                                        if (emails !== "") {
                                            let invalidEmails = validateIfTargetsAreValidEmails(emails);
                                            if (invalidEmails.length > 0) {
                                                statementDeliveryEmailValidationError.innerHTML = "You have entered at least one invalid email addresses: " + invalidEmails.join("; ");
                                            }
                                        }
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            statementDeliveryEmail: emails,
                                        })
                                    }}
                                    ref={props.statementDeliveryEmailRef}
                                />
                                <div className="mandatory-fields-hint" ref={(message) => { statementDeliveryEmailValidationError = message; }}></div>
                            </Grid>
                        </Grid>

                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Schema Version<LightTooltip title="Schema Version" placement="right-start"
                                    arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="schema-version"
                                        name="schema-version"
                                        value={props.generalSettings.schemaVersion ? props.generalSettings.schemaVersion : '20210803'}
                                        onChange={(event) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                schemaVersion: event.target.value as string,
                                            })
                                        }}
                                    >
                                        <MenuItem value={'20210803'}>20210803</MenuItem>
                                        <MenuItem value={'20230101'}>20230101</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Raw Data Export Format<LightTooltip title="Raw Data Export Format" placement="right-start"
                                    arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="raw-data-export-format"
                                        name="raw-data-export-format"
                                        value={props.generalSettings.dataExportFormat ? props.generalSettings.dataExportFormat : ''}
                                        onChange={(event) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                dataExportFormat: event.target.value as string,
                                            })
                                        }}
                                    >
                                        <MenuItem value={'xml'}>XML</MenuItem>
                                        <MenuItem value={'json'}>JSON</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Statement Output Format<LightTooltip title="Statement Output Format" placement="right-start"
                                    arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="statement-output-format"
                                        name="statement-output-format"
                                        value={props.generalSettings.statementExportFormat ? props.generalSettings.statementExportFormat : ''}
                                        onChange={(event) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                statementExportFormat: event.target.value as string,
                                            })
                                        }}
                                    >
                                        <MenuItem value={'pdf'}>PDF Only</MenuItem>
                                        <MenuItem value={'html'}>HTML Only</MenuItem>
                                        <MenuItem value={'pdf & html'}>PDF & HTML</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Statement Transaction Period (Days)<LightTooltip title="Statement Transaction Period (Days)"
                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="statement-transaction-period-days"
                                    className="w-100"
                                    value={props.generalSettings.statementTransactionPeriodDays}
                                    type="number"
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            statementTransactionPeriodDays: event.target.value,
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Statement Delivery Endpoint
                                    <LightTooltip title="Please specify one or more URLs each separated by semi-colon as a delimiter, e.g. https://test1.com.au; https://test2.com.au; ...." placement="right-start" arrow>
                                        <InfoIcon color={"secondary"} />
                                    </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="statement-delivery-endpoint"
                                    className="w-100"
                                    value={props.generalSettings.statementDeliveryEndpoint}
                                    onChange={(event) => {
                                        statementDeliveryEndpointValidationError.innerHTML = "";
                                        let urls = event.target.value;
                                        if (urls !== "") {
                                            let invalidUrls = validateIfTargetsAreValidUrls(urls);
                                            if (invalidUrls.length > 0) {
                                                statementDeliveryEndpointValidationError.innerHTML = "You have entered at least one invalid endpoint URL: " + invalidUrls.join("; ");
                                            }
                                        }
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            statementDeliveryEndpoint: urls,
                                        })
                                    }}
                                    ref={props.statementDeliveryEndpointRef}
                                />
                                <div className="mandatory-fields-hint" ref={(message) => { statementDeliveryEndpointValidationError = message; }}></div>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Deliver E-Statement<LightTooltip title="Deliver E-Statement" placement="right-start"
                                    arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="is_deliver_estatement"
                                        name="is_deliver_estatement"
                                        value={props.generalSettings.isDeliverEstatement ? props.generalSettings.isDeliverEstatement : 0}
                                        onChange={(event) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                isDeliverEstatement: event.target.value as boolean,
                                            })
                                        }}
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Consent Expiry Notification Endpoint
                                    <LightTooltip title="Please specify one or more URLs each separated by semi-colon as a delimiter, e.g. https://test1.com.au; https://test2.com.au; ...." placement="right-start" arrow>
                                        <InfoIcon color={"secondary"} />
                                    </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="statement-delivery-endpoint"
                                    className="w-100"
                                    value={props.generalSettings.consentExpiryNotificationEndpoint}
                                    onChange={(event) => {
                                        consentExpiryNotificationEndpointValidationError.innerHTML = "";
                                        let urls = event.target.value;
                                        if (urls !== "") {
                                            let invalidUrls = validateIfTargetsAreValidUrls(urls);
                                            if (invalidUrls.length > 0) {
                                                consentExpiryNotificationEndpointValidationError.innerHTML = "You have entered at least one invalid endpoint URL: " + invalidUrls.join("; ");
                                            }
                                        }
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            consentExpiryNotificationEndpoint: event.target.value,
                                        })
                                    }}
                                    ref={props.consentExpiryEndpointRef}
                                />
                                <div className="mandatory-fields-hint" ref={(message) => { consentExpiryNotificationEndpointValidationError = message; }}></div>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>JS Post Message Domain URL
                                    <LightTooltip title="This specifies the domain for a JS Post Message - it should be set to the domain (e.g. http://yourdomain.com) of your application from which the JS Post message data from the Consent Flow will be sent to the parent frame"
                                        placement="right-start" arrow>
                                        <InfoIcon color={"secondary"} />
                                    </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="cdr-policy-url"
                                    className="w-100"
                                    value={props.generalSettings.callbackURL}
                                    placeholder="JS Post Message Domain URL"
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            callbackURL: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Reporting Delivery Email
                                    <LightTooltip title="Please specify Reporting Delivery Email" placement="right-start" arrow>
                                        <InfoIcon color={"secondary"} />
                                    </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="cdr-policy-url"
                                    className="w-100"
                                    value={props.generalSettings.reportingDeliveryEmail}
                                    placeholder="Reporting Delivery Email"
                                    onChange={(event) => {
                                        reportingDeliveryEmailValidationError.innerHTML = "";
                                        let emails = event.target.value;
                                        reportingDeliveryEmailValidationError.innerHTML = "";
                                        if (emails !== "") {
                                            let invalidEmails = validateIfTargetsAreValidEmails(emails);
                                            if (invalidEmails.length > 0) {
                                                reportingDeliveryEmailValidationError.innerHTML = "You have entered at least one invalid email addresses: " + invalidEmails.join("; ");
                                            }
                                        }
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            reportingDeliveryEmail: event.target.value as string
                                        })
                                    }}
                                />
                                <div className="mandatory-fields-hint" ref={(message) => { reportingDeliveryEmailValidationError = message; }}></div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Box mt={5} mb={3}><h6>Consent Email Notifications</h6></Box>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Send Consent Expiry Email?<LightTooltip title="Send Consent Expiry Email?"
                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="send-consent-expiry-email"
                                        name="send-consent-expiry-email"
                                        value={props.generalSettings.sendConsentExpiryEmail ? props.generalSettings.sendConsentExpiryEmail : 0}
                                        onChange={(event) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                sendConsentExpiryEmail: event.target.value as boolean,
                                            })
                                        }}
                                    >
                                        <MenuItem value={0}>No</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Consent Expiry Email Text<LightTooltip title="Consent Expiry Email Text"
                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="consent-expiry-email-text"
                                    className="w-100"
                                    multiline={true}
                                    value={props.generalSettings.consentExpiryEmailText}
                                    placeholder="Consent Expiry email main text"
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            consentExpiryEmailText: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Box mt={5} mb={3}><h6>Consent API Webhook Notifications</h6></Box>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Consent Confirmation URL<LightTooltip title="Consent Confirmation URL"
                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="consent-confirmation-url"
                                    className="w-100"
                                    value={props.generalSettings.consentConfirmationPdfURL}
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            consentConfirmationPdfURL: event.target.value,
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Consent Withdrawal URL<LightTooltip title="Consent Withdrawal URL"
                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="consent-withdrawal-url"
                                    className="w-100"
                                    value={props.generalSettings.consentRevocationPdfURL}
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            consentRevocationPdfURL: event.target.value,
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Recurring Consent Reminder URL<LightTooltip title="Recurring Consent Reminder URL"
                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="recurring-consent-reminder-URL"
                                    className="w-100"
                                    value={props.generalSettings.consentRecurringNotificationURL}
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            consentRecurringNotificationURL: event.target.value,
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Consent Expiry URL<LightTooltip title="Consent Expiry URL"
                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="consent-expiry-url"
                                    className="w-100"
                                    value={props.generalSettings.consentExpiryPdfURL}
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            consentExpiryPdfURL: event.target.value,
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {isAdmin(props.currentUser?.role_id) ?
                    <>
                    <Box mt={5} mb={3}><h6>MTLS</h6></Box>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={6}>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>MTLS Cert/Key Configuration:<LightTooltip title="MTLS Certificate Set"
                                                                                 placement="right-start" arrow><InfoIcon
                                        color={"secondary"} /></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.mtlsCertOptions &&
                                    <Select
                                        name="font-family-headings"
                                        value={props.generalSettings.mtlsCertificatesId ? props.generalSettings.mtlsCertificatesId : "0"}
                                        onChange={(event: SelectChangeEvent) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                mtlsCertificatesId: event.target.value
                                            })
                                        }}
                                    >

                                        <MenuItem value={"0"}>No MTLS</MenuItem>
                                        {props.mtlsCertOptions.map(item =>
                                            <MenuItem value={item.identifier} key={item.identifier}>{item.name}</MenuItem>
                                        )}
                                    </Select>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    </>
                    : ""}
            </div>

            <hr className={"mt-30"} />

            <div className={"mt-50"}>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <h4>Redirection Settings</h4>
                        </Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Success Redirect URL
                                    <LightTooltip title="This specifies a URL for the Consent Flow to redirect back to after the successful completion of the Consent Flow. It provided it will automatically triger to redirect to the specifid location after 5 seconds"
                                        placement="right-start" arrow>
                                        <InfoIcon color={"secondary"} />
                                    </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="cdr-policy-url"
                                    className="w-100"
                                    value={props.generalSettings.redirectURL}
                                    placeholder="Success Redirect URL"
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            redirectURL: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Redirect After Seconds
                                    <LightTooltip title="Automatically triger to redirect to the specifid location after setup number of seconds"
                                                  placement="right-start" arrow>
                                        <InfoIcon color={"secondary"} />
                                    </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="cdr-policy-url"
                                    className="w-100"
                                    type="number"
                                    value={props.generalSettings.redirectAfterSeconds}
                                    placeholder="Redirect After Seconds"
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            redirectAfterSeconds: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Box mt={5} mb={3}><h6>Cancellation Settings</h6></Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Show Cancel Button
                                    <LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/pre-consent/Cancel_button_cdr.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"} />
                                    </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SwitchWithLabel
                                    checked={props.generalSettings.includeCancelButtonAndPopUp}
                                    onChange={value => handleGeneralSettingsChange({ ...props.generalSettings, includeCancelButtonAndPopUp: value })}
                                >
                                    {props.generalSettings.includeCancelButtonAndPopUp ? "Include" : "Exclude"}
                                </SwitchWithLabel>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Cancellation Redirect URL
                                    <LightTooltip title="This specifies a URL for the Consent Flow to redirect back to after the user cancels the Consent Flow. If provided, the redirect will be triggered to this URL after the user confirms cancellation."
                                        placement="right-start" arrow>
                                        <InfoIcon color={"secondary"} />
                                    </LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="cancellation-redirect-url"
                                    className="w-100"
                                    value={props.generalSettings.cancellationRedirectURL}
                                    placeholder="Cancellation Redirect URL"
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            cancellationRedirectURL: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Cancellation Modal Heading
                                    <LightTooltip title="Cancel modal heading" placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="cancel-modal-heading"
                                    className="w-100"
                                    value={props.generalSettings.cancelModalPopUpHeading}
                                    placeholder="Cancel modal heading text"
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            cancelModalPopUpHeading: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Cancellation Modal Text
                                    <LightTooltip title="Cancel modal text" placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="cancel-modal-text"
                                    className="w-100"
                                    multiline={true}
                                    value={props.generalSettings.cancelModalPopUpMainText}
                                    placeholder="Cancel modal main text"
                                    onChange={(event) => {
                                        handleGeneralSettingsChange({
                                            ...props.generalSettings,
                                            cancelModalPopUpMainText: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Box mt={5} mb={3}><h6>Enhanced Redirection Settings</h6></Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Enable for success</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SwitchWithLabel
                                    checked={props.generalSettings.enhancedRedirection?.enableForSuccess || false}
                                    onChange={value => handleEnhancedRedirectionSettingsChange({ ...props.generalSettings.enhancedRedirection, enableForSuccess: value })}
                                >
                                    {props.generalSettings.enhancedRedirection?.enableForSuccess ? "Include" : "Exclude"}
                                </SwitchWithLabel>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Enable for cancellation</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SwitchWithLabel
                                    checked={props.generalSettings.enhancedRedirection?.enableForCancellation || false}
                                    onChange={value => handleEnhancedRedirectionSettingsChange({ ...props.generalSettings.enhancedRedirection, enableForCancellation: value })}
                                >
                                    {props.generalSettings.enhancedRedirection?.enableForCancellation ? "Include" : "Exclude"}
                                </SwitchWithLabel>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Client ID</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="enhanced-redirection-client-id"
                                    className="w-100"
                                    value={props.generalSettings.enhancedRedirection?.clientId}
                                    placeholder="Client ID"
                                    onChange={(event) => {
                                        handleEnhancedRedirectionSettingsChange({
                                            ...props.generalSettings.enhancedRedirection,
                                            clientId: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Client Secret</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="enhanced-redirection-client-secret"
                                    className="w-100"
                                    value={props.generalSettings.enhancedRedirection?.clientSecret}
                                    placeholder="Client Secret"
                                    onChange={(event) => {
                                        handleEnhancedRedirectionSettingsChange({
                                            ...props.generalSettings.enhancedRedirection,
                                            clientSecret: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Client credentials API endpoint URL</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="enhanced-redirection-client-credentials-endpoint"
                                    className="w-100"
                                    value={props.generalSettings.enhancedRedirection?.clientCredentialsEndpoint}
                                    placeholder="Client credentials API endpoint URL"
                                    onChange={(event) => {
                                        handleEnhancedRedirectionSettingsChange({
                                            ...props.generalSettings.enhancedRedirection,
                                            clientCredentialsEndpoint: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>JWT generation API endpoint URL</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="enhanced-redirection-jwt-generation-endpoint"
                                    className="w-100"
                                    value={props.generalSettings.enhancedRedirection?.jwtGenerationEndpoint}
                                    placeholder="JWT generation API endpoint URL"
                                    onChange={(event) => {
                                        handleEnhancedRedirectionSettingsChange({
                                            ...props.generalSettings.enhancedRedirection,
                                            jwtGenerationEndpoint: event.target.value as string
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <hr className={"mt-30"} />

            <div className={"mt-50"}>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <h4>Additional Settings</h4>
                        </Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Banks Overridden <br />For CDR Data Retrieval<LightTooltip title="Override CDR data retrieval"
                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"} /></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {dataHolders.length > 0 && (
                                    <Autocomplete
                                        multiple
                                        id="tags-outlined"
                                        options={dataHolders}
                                        defaultValue={getSelectedCdrRetrievalOverridedDataHoldersOptions()}
                                        getOptionLabel={(option: CdrRetrievalOverridedDataHolder) => option.name || ''}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder="Override CDR data retrieval"
                                            />
                                        )}
                                        onChange={(event, value) => {
                                            handleGeneralSettingsChange({
                                                ...props.generalSettings,
                                                cdrRetrievalOverridedDataHolders: value,
                                            })
                                        }}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            {isAdmin(props.currentUser?.role_id) ?
                <>
                    <hr className={"mt-30"} />

                    <div className={"mt-50"}>
                        <Grid container spacing={10}>
                            <Grid item xs={12} md={6}>
                                <Box mb={2}>
                                    <h4>Direct Data Capture Settings</h4>
                                </Box>

                                <Grid container className={"form-group"}>
                                    <Grid item xs={12} sm={6} className={"align-self-center"}>
                                        <label>BankFeeds API key</label>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            name="bank-feeds-api-key"
                                            className="w-100"
                                            value={props.generalSettings.directDataCaptureAPIKey}
                                            placeholder="BankFeeds API key"
                                            onChange={(event) => {
                                                handleGeneralSettingsChange({
                                                    ...props.generalSettings,
                                                    directDataCaptureAPIKey: event.target.value as string
                                                })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container className={"form-group"}>
                                    <Grid item xs={12} sm={6} className={"align-self-center"}>
                                        <label>Once-Off Data Retrieval Only?<LightTooltip
                                            title={"BankFeeds Synchronous Data Retrieval?"}
                                            placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Select
                                            id="is-ddc-sync-data-retrieval"
                                            name="is-ddc-sync-data-retrieval"
                                            value={props.generalSettings.directDataCaptureOnceOffRetrieval ? props.generalSettings.directDataCaptureOnceOffRetrieval : 0}
                                            onChange={(event) => {
                                                handleGeneralSettingsChange({
                                                    ...props.generalSettings,
                                                    directDataCaptureOnceOffRetrieval: event.target.value as boolean,
                                                })
                                            }}
                                        >
                                            <MenuItem value={0}>No</MenuItem>
                                            <MenuItem value={1}>Yes</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </> : ""
            }

            <hr className={"mt-30"} />

            <div className={"mt-50"}>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <h4>External Data Pipelines</h4>
                        </Box>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Enhanced Categorisation</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SwitchWithLabel
                                    checked={props.generalSettings.enhancedCategorisation || false}
                                    onChange={value => handleGeneralSettingsChange({ ...props.generalSettings, enhancedCategorisation: value })}
                                >
                                    {props.generalSettings.enhancedCategorisation ? "Include" : "Exclude"}
                                </SwitchWithLabel>                
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>iTRS Score</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SwitchWithLabel
                                    checked={props.generalSettings.itrsScore || false}
                                    onChange={value => handleGeneralSettingsChange({ ...props.generalSettings, itrsScore: value })}
                                >
                                    {props.generalSettings.itrsScore ? "Include" : "Exclude"}
                                </SwitchWithLabel>                
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Categorisation Improvement</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SwitchWithLabel
                                    checked={props.generalSettings.categorisationImprovement || false}
                                    onChange={value => handleGeneralSettingsChange({ ...props.generalSettings, categorisationImprovement: value })}
                                >
                                    {props.generalSettings.categorisationImprovement ? "Include" : "Exclude"}
                                </SwitchWithLabel>                
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={6} className={"align-self-center"}>
                                <label>Spend Analytics</label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SwitchWithLabel
                                    checked={props.generalSettings.spendAnalytics || false}
                                    onChange={value => handleGeneralSettingsChange({ ...props.generalSettings, spendAnalytics: value })}
                                >
                                    {props.generalSettings.spendAnalytics ? "Include" : "Exclude"}
                                </SwitchWithLabel>                
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </form>

        {/* Preview popup */}
        <Popup
            key={"preview-popup"}
            maxWidth={"md"}
            open={props.previewSettings}
            withCloseButton
            onClose={props.onClosePreviewSettings}
        >
            <ThemeProvider<Theme> theme={customTheme}>
                <Header 
                    title="" 
                    generalSettings={props.generalSettings} 
                    principalLogoUrl={"/images/logo-illion.png"} 
                    headerBgImageUrl={props.generalSettings.headerBackgroundImage} 
                    isTrustedAdviser={false} />
                <Box component={"div"} className={"pt-20 pb-30"} sx={styles.primaryBlock}>
                    <Container>
                        <h5><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />&nbsp; Lorem ipsum dolor sit amet</h5>
                        <Divider component={"hr"} className={"dashed"} sx={styles.separator} />
                        <Box mt={4}>
                            <h2><strong>Lorem ipsum dolor sit amet</strong></h2>
                            <p>Hasellus vitae accumsan lectus. Cras eget porta tortor, a accumsan nibh. Maecenas fringilla in velit eget facilisis.</p>
                            <AccreditationBadge
                                companyName={"Your business name"}
                                accreditationNumber={"123456"}
                                displayStyle={2}
                            />

                            <h3><FontAwesomeIcon icon={faAngleRight} /> Pellentesque tristique dolor enim</h3>
                            <p>Hasellus vitae accumsan lectus. Cras eget porta tortor, a accumsan nibh. Maecenas fringilla in velit eget facilisis.</p>

                            <Box component={"div"} className={"highlight-block data-request-card"} sx={styles.dataClusterCard}>
                                <label>
                                    <SwitchWithLabel checked={true}>
                                        <Typography component={"span"} color={"inherit"} className="typo-display">
                                            <h5>Mauris ligula tortor</h5>
                                            <p className="mandatory-field-indicator">&nbsp;*</p>
                                        </Typography>
                                    </SwitchWithLabel>
                                </label>
                                <h6>Why we need it</h6>
                                <p>Vestibulum ante ipsum</p>
                            </Box>

                            <Box component={"div"} className={"highlight-block data-request-card"} sx={styles.dataClusterCard}>
                                <label>
                                    <SwitchWithLabel checked={false}>
                                        <Typography component={"span"} className="typo-display">
                                            <h5>Pellentesque habitant morbi</h5>
                                        </Typography>
                                    </SwitchWithLabel>
                                </label>
                                <h6>Why we need it</h6>
                                <p>Duis rhoncus lectus</p>
                            </Box>
                        </Box>
                    </Container>
                </Box>

                <Box component={"div"} className={"secondary-block"} sx={styles.secondaryBlock}>
                    <Container>
                        <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
                        <div className={"content"}>
                            <Container maxWidth={"sm"} className={"p-0"}>
                                <p>Aliquam accumsan ultricies leo, sed maximus nunc mollis vel. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                            </Container>
                        </div>
                        <div className={"cta"}>
                            <ButtonBlock>
                                <Button variant={"outlined"} color={"secondary"}>Secondary action</Button>
                                <Button variant={"contained"} color={"secondary"}>Primary action</Button>
                            </ButtonBlock>
                        </div>
                    </Container>
                </Box>
                <Box component={"div"} 
                    className={"footer page-bottom"} 
                    sx={{
                        backgroundColor: props.generalSettings.footerBackgroundColor, 
                        color: props.generalSettings.footerTextColor, 
                        alignContent: props.generalSettings.footerTextAlignment,
                        paddingLeft: "15px",
                        paddingBottom: "5px"}}>
                        <img src="/images/logo-illion.png" alt="" style={{ maxWidth: "200px", maxHeight: "45px", marginBottom: "20px", marginTop: "2em" }} /> 

                    <footer style={{
                        backgroundColor: props.generalSettings.footerBackgroundColor, 
                        color: props.generalSettings.footerTextColor, 
                        alignContent: props.generalSettings.footerTextAlignment}}>
                        {(props.generalSettings?.footerText && hasRichTextSpecifiedForField(props.generalSettings.footerText)) ?
                            <EditableRichText
                                editMode={false}
                                value={props.generalSettings.footerText}
                            /> :
                            <>Please read our <a href={props.generalSettings?.cdrPolicyURL}>Consumer Data Right policy</a> for some information on how {sanitize(props.generalSettings?.principalName)} handles your data.</>
                        }
                    </footer>
                </Box>
            </ThemeProvider>
        </Popup>

        {/* Branding image validation error popup */}
        <Popup
            key={"image-validation-error-popup"}
            open={openIVE}
            withCloseButton
            onClose={closeIVEPopup}
            buttons={<Button onClick={closeIVEPopup} variant={"contained"} color={"secondary"}>Close</Button>}
        >
            <Typography color={"primary"}>
                <h2>Invalid image size</h2>
                <p>Your brandmark should be no larger than 500px by 500px.</p>
            </Typography>
        </Popup>

        {/* Header background image validation error popup */}
        <Popup
            key={"image-validation-error-popup-2"}
            open={openIVE2}
            withCloseButton
            onClose={closeIVEPopup2}
            buttons={<Button onClick={closeIVEPopup2} variant={"contained"} color={"secondary"}>Close</Button>}
        >
            <Typography color={"primary"}>
                <h2>Invalid image size</h2>
                <p>Your header background image should be no larger than 1920px by 128px.</p>
            </Typography>
        </Popup>

        {/* Accreditation icon image validation error popup */}
        <Popup
            key={"image-validation-error-popup-3"}
            open={openIVE3}
            withCloseButton
            onClose={closeIVEPopup3}
            buttons={<Button onClick={closeIVEPopup3} variant={"contained"} color={"secondary"}>Close</Button>}
        >
            <Typography color={"primary"}>
                <h2>Invalid image size</h2>
                <p>Your icon should be no larger than 500px by 500px.</p>
            </Typography>
        </Popup>

        {/* Accreditation icon image validation error popup */}
        <Popup
            key={"image-validation-error-popup-4"}
            open={openIVE4}
            withCloseButton
            onClose={closeIVEPopup4}
            buttons={<Button onClick={closeIVEPopup4} variant={"contained"} color={"secondary"}>Close</Button>}
        >
            <Typography color={"primary"}>
                <h2>Invalid Image File</h2>
                <p>Image to be uploaded must either be a JPG or PNG file.</p>
            </Typography>
        </Popup>

        {/* Accreditation icon image validation error popup */}
        <Popup
            key={"image-validation-error-popup-5"}
            open={openIVE5}
            withCloseButton
            onClose={closeIVEPopup5}
            buttons={<Button onClick={closeIVEPopup5} variant={"contained"} color={"secondary"}>Close</Button>}
        >
            <Typography color={"primary"}> 
                <h2>Max Upload File Size Exceeded</h2>
                <p>File uploaded must be less than 5MB in size.</p>
            </Typography>
        </Popup>

        {/* CDR policy PDF validation error popup */}
        <Popup
            key={"pdf-validation-error-popup"}
            open={openPVE}
            withCloseButton
            onClose={closePVEPopup}
            buttons={<Button onClick={closePVEPopup} variant={"contained"} color={"secondary"}>Close</Button>}
        >
            <Typography color={"primary"}>
                <h2>Invalid PDF File</h2>
                <p>The file you are uploading does not appear to be a valid PDF file.</p>
            </Typography>
        </Popup>
    </>;
}

export default EditGeneralSettings;
